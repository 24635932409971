import type { AuthListener } from "@wayflyer/auth-sdk";
import { AuthEvent, AuthProvider, AxiosProvider } from "@wayflyer/auth-sdk";
import { useCallback } from "react";
import { useHref, useNavigate, useRoutes } from "react-router-dom";

import { USE_DEV_MODE } from "../../../utils/env";
import { isLocalhost } from "../../../utils/host";
import { ROUTES } from "../sharedRoutes";

import { staffRoutes } from "./staffRoutes";

export const StaffRoutes = () => {
  const rendered = useRoutes(staffRoutes);

  const navigate = useNavigate();
  const goToStaffApp = useCallback(() => {
    navigate(ROUTES.REDIRECT, { replace: true });
  }, [navigate]);

  const listeners: AuthListener[] = USE_DEV_MODE
    ? [
        {
          event: AuthEvent.SIGNIN_SUCCESS,
          callback: async () => {
            goToStaffApp();
          },
          name: "staff-login-dev-mode",
        },
      ]
    : [
        {
          event: AuthEvent.OAUTH_COMPLETE,
          callback: async () => {
            goToStaffApp();
          },
          name: "staff-login",
        },
      ];

  const cookieDomain = isLocalhost() ? "localhost" : window.location.host;
  const oAuthRedirect = useHref(ROUTES.OAUTH);
  const oAuthLogout = useHref(ROUTES.LOGIN); // Redirect to login after OAuth logout

  return (
    <AuthProvider
      config={{
        ...__WF_AUTH_CONFIGS__.staff,
        authApiBaseUrl: window.location.origin,
        cookieDomain,
        oauthRedirectUrl: `${window.location.origin}${oAuthRedirect}`,
        oauthLogoutUrl: `${window.location.origin}${oAuthLogout}`,
      }}
      listeners={() => {
        return listeners;
      }}
      withSessionRefresher={false}
    >
      <AxiosProvider>
        <>{rendered}</>
      </AxiosProvider>
    </AuthProvider>
  );
};
